import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatGridListModule} from '@angular/material';
import {DetailGridComponent} from './detail-grid/detail-grid.component';
import {DetailGridItemComponent} from './detail-grid/detail-grid-item/detail-grid-item.component';

@NgModule({
    declarations: [
        DetailGridComponent,
        DetailGridItemComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule
    ],
    exports: [
        DetailGridComponent,
        DetailGridItemComponent
    ]
})
export class SharedModule {
}
