import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = AuthenticationService.getToken();

        if (token) {
            const clonedRequest = req.clone({
                headers: req.headers.set('X-Auth-Token', token)
            });
            return next.handle(clonedRequest);
        }
        return next.handle(req);
    }

}
