import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {routingAnimation} from './animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        routingAnimation
    ]
})
export class AppComponent {

    prepareRoute(outlet: RouterOutlet) {
        // console.log(outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation);
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
    }
}
