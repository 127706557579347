import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from './authentication.service';
import {Subscription} from 'rxjs';
import {Role} from './role';

@Directive({
    selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {

    @Input() appHasRole?: string | string[];
    private currentUser$?: Subscription;

    constructor(private viewContainerRef: ViewContainerRef,
                private templateRef: TemplateRef<any>,
                private authenticationService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.currentUser$ = this.authenticationService.currentUser.subscribe(currentUser => {
            if (this.appHasRole) {
                const roles = this.convertInputToRoles(this.appHasRole);
                if (currentUser && currentUser.hasAnyRole(roles)) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                } else {
                    this.viewContainerRef.clear();
                }
            } else {
                this.viewContainerRef.createEmbeddedView(this.templateRef);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.currentUser$) {
            this.currentUser$.unsubscribe();
        }
    }

    private convertInputToRoles(input: string | string[]): Role[] {
        const roles = new Array<string>().concat(input);
        return roles.map(value => Role[value as keyof typeof Role]);
    }

}
