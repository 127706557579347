/**
 * JSON representation of the pricing information.
 */
import {Customer, CustomerJSON} from './customer';

export interface PricingJSON {
    id: number;
    customer: CustomerJSON;
    fromDate: string;
    price: number;
}

export interface PricingUpdateJSON {
    id: number | null;
    fromDate: string;
    price: number;
}

/**
 * Represents a pricing.
 */
export class Pricing {
    constructor(
        public id: number | null,
        public customer: Customer,
        public fromDate: Date,
        public price: number
    ) {
    }

    static deserialize(raw: PricingJSON): Pricing {
        return new Pricing(raw.id, Customer.deserialize(raw.customer), new Date(raw.fromDate), raw.price);
    }
}
