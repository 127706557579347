import {MatPaginatorIntl} from '@angular/material';

export function PaginatorConfiguration() {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = 'Einträge pro Seite:';
    customPaginatorIntl.firstPageLabel = 'Erste';
    customPaginatorIntl.lastPageLabel = 'Letzte';
    customPaginatorIntl.previousPageLabel = 'Vorherige';
    customPaginatorIntl.nextPageLabel = 'Nächste';
    customPaginatorIntl.getRangeLabel = (page, pageSize, length) => {
        const startRow = page * pageSize + 1;
        let endRow = startRow + pageSize - 1;
        if (endRow > length) {
            endRow = length;
        }
        return `${startRow}-${endRow} von ${length}`;
    };

    return customPaginatorIntl;
}
