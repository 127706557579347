import {Component, OnInit} from '@angular/core';
import {MetricReport} from '../../core/services/metric-report';
import {MetricReportService} from '../../core/services/metric-report.service';
import {MetricReportFilterCriteria} from '../../core/services/metric-report-filter-criteria';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {MetricReportDetailsComponent} from './metric-report-details/metric-report-details.component';
import {MessagesService} from '../../core/messages/messages.service';
import {MetricReportStatusFormComponent} from './metric-report-status-form/metric-report-status-form.component';
import {Observable} from 'rxjs';
import {MetricReportsChartDialogComponent} from '../metric-reports-chart-dialog/metric-reports-chart-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-metric-reports',
    templateUrl: './metric-reports.component.html',
    styleUrls: ['./metric-reports.component.scss']
})
export class MetricReportsComponent implements OnInit {

    metricReports: MetricReport[] = [];

    private activeFilterCriteria?: MetricReportFilterCriteria;

    constructor(private metricReportService: MetricReportService, private dialog: MatDialog, private messagesService: MessagesService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        this.reloadMetricReportList();
    }

    onFilterUpdated(criteria?: MetricReportFilterCriteria): void {
        this.activeFilterCriteria = criteria;
        this.reloadMetricReportList();
    }

    onReload(): void {
        this.reloadMetricReportList();
    }

    onView(metricReport: MetricReport): void {
        this.openDetailsDialogFor(metricReport);
    }

    onUpdateStatus(metricReports: MetricReport[]): void {
        this.openStatusUpdateDialog().subscribe(result => {
            if (result) {
                const ids = metricReports.map(entry => entry.id);
                this.metricReportService.bulkUpdateAccountedStatus(ids, result).subscribe(updatedEntries => {
                    this.messagesService.showInfoMessage(`${updatedEntries.length} Einträge wurden aktualisiert.`);
                    this.reloadMetricReportList();
                });
            }
        });
    }

    onExport(): void {
        this.metricReportService.loadCSVFilteredBy(this.activeFilterCriteria).subscribe((result: string) => {
            const blob = new Blob([result], {type: 'text/csv'});
            const exportUrl = window.URL.createObjectURL(blob);
            window.location.href = exportUrl.toString();
        });
    }

    onDisplayChart(): void {
        this.openChartDialog();
    }

    private reloadMetricReportList(): void {
        if (this.activeFilterCriteria && !this.activeFilterCriteria.isEmpty()) {
            this.metricReportService.loadFilteredBy(this.activeFilterCriteria).subscribe(values => this.metricReports = values);
        } else {
            this.metricReportService.loadAll().subscribe(values => this.metricReports = values);
        }
    }

    private openDetailsDialogFor(metricReport: MetricReport): void {
        this.metricReportService.loadBy(metricReport.id).subscribe(updatedMetricReport => {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = updatedMetricReport;
            this.dialog.open(MetricReportDetailsComponent, dialogConfig);
        }, () => this.messagesService.showErrorMessage('Der Datensatz wurde nicht gefunden.'));
    }

    private openStatusUpdateDialog(): Observable<boolean> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(MetricReportStatusFormComponent, dialogConfig);
        return dialogRef.afterClosed();
    }

    private openChartDialog(): void {
        this.metricReportService.loadChartDataFilteredBy(this.activeFilterCriteria).subscribe(chartData => {
            if (chartData.count === 0) {
                // No data available.
                this.messagesService.showInfoMessage('Es gibt keine Daten zum Anzeigen.');
                return;
            }
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = false;
            dialogConfig.data = chartData;
            this.dialog.open(MetricReportsChartDialogComponent, dialogConfig);
        }, () => this.messagesService.showErrorMessage('Das Diagramm steht nicht zur Verfügung.'));
    }
}
