import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {NavigationComponent} from './navigation/navigation.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorIntl,
    MatToolbarModule
} from '@angular/material';
import {CoreModule} from './core/core.module';
import {LoginComponent} from './login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AccountingModule} from './accounting/accounting.module';

import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {PaginatorConfiguration} from './paginator-configuration';
import {StatisticsModule} from './statistics/statistics.module';
import {AdministrationModule} from './administration/administration.module';

registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NavigationComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatMomentDateModule,
        MatToolbarModule,
        MatButtonModule,
        MatGridListModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatDividerModule,
        AppRoutingModule,
        CoreModule,
        AccountingModule,
        StatisticsModule,
        AdministrationModule
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: MatPaginatorIntl, useValue: PaginatorConfiguration()}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
