import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AccountingRoutingModule} from './accounting-routing.module';
import {MetricReportsComponent} from './metric-reports/metric-reports.component';
import {MetricReportListComponent} from './metric-reports/metric-report-list/metric-report-list.component';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MetricReportListFilterComponent} from './metric-reports/metric-report-list-filter/metric-report-list-filter.component';
import {FormsModule} from '@angular/forms';
import {MetricReportDetailsComponent} from './metric-reports/metric-report-details/metric-report-details.component';
import {SharedModule} from '../shared/shared.module';
import {MetricReportStatusFormComponent} from './metric-reports/metric-report-status-form/metric-report-status-form.component';
import {CoreModule} from '../core/core.module';
import {MetricReportsChartDialogComponent} from './metric-reports-chart-dialog/metric-reports-chart-dialog.component';
import {ChartsModule} from 'ng2-charts';

@NgModule({
    declarations: [
        MetricReportsComponent,
        MetricReportListComponent,
        MetricReportListFilterComponent,
        MetricReportDetailsComponent,
        MetricReportStatusFormComponent,
        MetricReportsChartDialogComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatToolbarModule,
        MatIconModule,
        MatSortModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatDividerModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        ChartsModule,
        SharedModule,
        AccountingRoutingModule,
        CoreModule
    ],
    entryComponents: [
        MetricReportDetailsComponent,
        MetricReportStatusFormComponent,
        MetricReportsChartDialogComponent
    ]
})
export class AccountingModule {
}
