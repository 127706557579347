import {Injectable} from '@angular/core';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {Observable} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {

    private dialogRef?: MatDialogRef<ConfirmDialogComponent>;

    constructor(private dialog: MatDialog) {
    }

    public confirm(title: string, message: string, confirmActionTitle?: string): Observable<boolean> {
        this.dialogRef = this.dialog.open(ConfirmDialogComponent);
        this.dialogRef.componentInstance.title = title;
        this.dialogRef.componentInstance.message = message;
        if (confirmActionTitle) {
            this.dialogRef.componentInstance.confirmActionTitle = confirmActionTitle;
        }
        return this.dialogRef.afterClosed();
    }

}
