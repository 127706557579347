import {Component, ContentChildren, Input, QueryList} from '@angular/core';
import {DetailGridItemComponent} from './detail-grid-item/detail-grid-item.component';

@Component({
    selector: 'app-detail-grid',
    templateUrl: './detail-grid.component.html',
    styleUrls: ['./detail-grid.component.scss']
})
export class DetailGridComponent {

    @Input() columns = 1;
    @Input() rowHeight = 50;

    @ContentChildren(DetailGridItemComponent) items?: QueryList<DetailGridItemComponent>;

}
