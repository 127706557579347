import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './core/security/auth.guard';
import {Role} from './core/security/role';

const routes: Routes = [
    {
        path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard],
        data: {animation: 'HomePage', roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]}
    },
    {path: 'login', component: LoginComponent, pathMatch: 'full', data: {animation: 'LoginPage'}}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
