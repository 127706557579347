import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AdministrationRoutingModule} from './administration-routing.module';
import {PricingComponent} from './pricing/pricing.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDatepickerModule, MatDialogModule,
    MatFormFieldModule, MatGridListModule, MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatTableModule,
    MatToolbarModule
} from '@angular/material';
import {PricingCardComponent} from './pricing/pricing-card/pricing-card.component';
import {PricingDetailsComponent} from './pricing/pricing-details/pricing-details.component';
import {PricingFormComponent} from './pricing/pricing-form/pricing-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxCurrencyModule} from 'ngx-currency';

@NgModule({
    declarations: [PricingComponent, PricingCardComponent, PricingDetailsComponent, PricingFormComponent],
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatCardModule,
        MatRippleModule,
        MatButtonModule,
        MatTableModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatGridListModule,
        MatIconModule,
        MatDatepickerModule,
        NgxCurrencyModule,
        AdministrationRoutingModule
    ],
    entryComponents: [
        PricingFormComponent
    ]
})
export class AdministrationModule {
}
