import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';

/**
 * Service providing info and error messaging.
 */
@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    constructor(private snackbar: MatSnackBar) {
    }

    showInfoMessage(text: string): void {
        this.snackbar.open(text);
    }

    showErrorMessage(text: string): void {
        const config = new MatSnackBarConfig();
        config.panelClass = 'error-message';
        config.duration = 3000;
        this.snackbar.open(text, undefined, config);
    }

}
