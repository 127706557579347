import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MetricReport} from '../../../core/services/metric-report';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MetricReportFilterCriteria} from '../../../core/services/metric-report-filter-criteria';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SelectionModel} from '@angular/cdk/collections';
import {Role} from '../../../core/security/role';
import {AuthenticationService} from '../../../core/security/authentication.service';
import {Field} from '../../../shared/field';

@Component({
    selector: 'app-metric-report-list',
    templateUrl: './metric-report-list.component.html',
    styleUrls: ['./metric-report-list.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: 1
            })),
            state('closed', style({
                height: 0,
                opacity: 0
            })),
            transition('open => closed',
                animate('200ms')
            ),
            transition('closed => open',
                animate('200ms'))
        ])
    ]
})
export class MetricReportListComponent implements OnInit, OnChanges {

    @Input() metricReports: MetricReport[] = [];

    @Output() filterUpdated = new EventEmitter<MetricReportFilterCriteria | undefined>();
    @Output() reload = new EventEmitter();
    @Output() displayChart = new EventEmitter();
    @Output() view = new EventEmitter<MetricReport>();
    @Output() updateStatus = new EventEmitter<MetricReport[]>();
    @Output() export = new EventEmitter();

    @ViewChild(MatSort) sort?: MatSort;
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    dataSource = new MatTableDataSource<MetricReport>();
    selectionModel: SelectionModel<MetricReport>;

    private columnDefinitions: Field[] = [
        {name: 'selection'},
        {name: 'accountingKey'},
        {name: 'createdOn'},
        {name: 'windFarm', requiredRoles: [Role.CUSTOMER_ADMIN]},
        {name: 'windTurbine', requiredRoles: [Role.CUSTOMER_ADMIN]},
        {name: 'customer', requiredRoles: [Role.SYSTEM_ADMIN]},
        {name: 'contractor'},
        {name: 'category'},
        {name: 'creator'},
        {name: 'performedChecklistCreatedOn'},
        {name: 'accounted', requiredRoles: [Role.SYSTEM_ADMIN]},
    ];

    filterVisible = false;
    filterActive = false;
    currentFilterCriteria?: MetricReportFilterCriteria;

    constructor(private authenticationService: AuthenticationService) {
        this.selectionModel = new SelectionModel<MetricReport>(true, []);
    }

    ngOnInit() {
        if (this.sort) {
            this.sort.active = 'createdOn';
            this.sort.direction = 'desc';
            this.dataSource.sort = this.sort;
        }
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }
        this.dataSource.data = this.metricReports;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = changes.metricReports.currentValue;
        this.selectionModel.clear();
    }

    get columnsToDisplay(): string[] {
        const currentUser = this.authenticationService.currentUserDetails;
        if (currentUser) {
            return this.columnDefinitions
                .filter(entry => !entry.requiredRoles || currentUser.hasAnyRole(entry.requiredRoles))
                .map(entry => entry.name);
        }
        return [];
    }

    onReload(): void {
        this.reload.emit();
    }

    onDisplayChart(): void {
        this.displayChart.emit();
    }

    onFilterSwitch(checked: boolean): void {
        if (!checked) {
            this.filterActive = false;
            this.filterUpdated.emit(undefined);
        } else {
            this.filterActive = true;
            this.filterVisible = !this.currentFilterCriteria || this.currentFilterCriteria.isEmpty() || this.filterVisible;
            if (this.currentFilterCriteria && !this.currentFilterCriteria.isEmpty()) {
                this.onFilterUpdated(this.currentFilterCriteria);
            }
        }
    }

    onFilterUpdated(criteria: MetricReportFilterCriteria): void {
        this.currentFilterCriteria = criteria;
        this.filterActive = !criteria.isEmpty();
        this.filterUpdated.emit(criteria);
    }

    onViewDetails(metricReport: MetricReport): void {
        this.view.emit(metricReport);
    }

    isAllSelected(): boolean {
        const selectedCount = this.selectionModel.selected.length;
        const rowCount = this.dataSource.data.length;
        return selectedCount === rowCount;
    }

    onToggleAllSelection(): void {
        this.isAllSelected() ?
            this.selectionModel.clear() :
            this.dataSource.data.forEach(entry => this.selectionModel.select(entry));
    }

    onUpdateStatus(): void {
        this.updateStatus.emit(this.selectionModel.selected);
    }

    onExport(): void {
        this.export.emit();
    }
}
