/**
 * The metric report filter criteria.
 */

export class MetricReportFilterCriteria {
    public code?: string;
    public fromDate?: Date;
    public toDate?: Date;
    public customerName?: string;
    public contractorName?: string;
    public accounted?: boolean;
    public windFarm?: string;
    public windTurbine?: string;

    isEmpty(): boolean {
        return Object.keys(this).filter(key => this[key as keyof MetricReportFilterCriteria] !== null &&
            this[key as keyof MetricReportFilterCriteria] !== undefined).length === 0;
    }
}
