import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MetricReport, MetricReportJSON} from './metric-report';
import {map} from 'rxjs/operators';
import {MetricReportFilterCriteria} from './metric-report-filter-criteria';
import {MetricReportStatusUpdateJSON} from './metric-report-status-update';
import {ChartData} from './chart-data';

const METRIC_REPORTS_ENDPOINT = '/api/metric-reports';
const METRIC_REPORTS_CHART_ENDPOINT = '/api/diagram';

@Injectable({
    providedIn: 'root'
})
export class MetricReportService {

    constructor(private http: HttpClient) {
    }

    /**
     * Loads all metric reports from the backend.
     * @return an observable.
     */
    loadAll(): Observable<MetricReport[]> {
        return this.http.get<MetricReportJSON[]>(METRIC_REPORTS_ENDPOINT).pipe(
            map(raws =>
                raws.map(raw => MetricReport.deserialize(raw)))
        );
    }

    /**
     * Loads metric reports filtered by the given criteria.
     * @param criteria The criteria object.
     * @return an observable.
     */
    loadFilteredBy(criteria: MetricReportFilterCriteria): Observable<MetricReport[]> {
        const criteriaParams = this.extractParams(criteria);
        return this.http.get<MetricReportJSON[]>(METRIC_REPORTS_ENDPOINT, {params: criteriaParams}).pipe(
            map(raws =>
                raws.map(raw => MetricReport.deserialize(raw)))
        );
    }

    /**
     * Loads metric report chart data filtered by the given criteria.
     * @param criteria The (optional) criteria object.
     * @return an observable.
     */
    loadChartDataFilteredBy(criteria?: MetricReportFilterCriteria): Observable<ChartData> {
        const criteriaParams = this.extractParams(criteria);
        return this.http.get<ChartData>(METRIC_REPORTS_CHART_ENDPOINT, {params: criteriaParams});
    }

    // noinspection JSMethodCanBeStatic
    private extractParams(criteria?: MetricReportFilterCriteria): HttpParams {
        let criteriaParams = new HttpParams();
        if (criteria) {
            if (criteria.code) {
                criteriaParams = criteriaParams.set('code', criteria.code);
            }
            if (criteria.fromDate) {
                criteriaParams = criteriaParams.set('from-date', criteria.fromDate.toISOString());
            }
            if (criteria.toDate) {
                criteriaParams = criteriaParams.set('to-date', criteria.toDate.toISOString());
            }
            if (criteria.windFarm) {
                criteriaParams = criteriaParams.set('wind-farm', criteria.windFarm);
            }
            if (criteria.windTurbine) {
                criteriaParams = criteriaParams.set('wind-turbine', criteria.windTurbine);
            }
            if (criteria.customerName) {
                criteriaParams = criteriaParams.set('customer-name', criteria.customerName);
            }
            if (criteria.contractorName) {
                criteriaParams = criteriaParams.set('contractor-name', criteria.contractorName);
            }
            if (criteria.accounted !== undefined) {
                criteriaParams = criteriaParams.set('accounted', String(criteria.accounted));
            }
        }
        return criteriaParams;
    }

    /**
     * Loads metric reports filtered by the given criteria as CSV.
     * @param criteria The criteria object.
     * @return an observable containing the CSV (string) stream.
     */
    loadCSVFilteredBy(criteria?: MetricReportFilterCriteria): Observable<string> {
        const criteriaParams = this.extractParams(criteria);
        let headerValues = new HttpHeaders();
        headerValues = headerValues.set('accept', 'text/csv');
        // @ts-ignore
        return this.http.get<string>(METRIC_REPORTS_ENDPOINT, {params: criteriaParams, headers: headerValues, responseType: 'text'});
    }

    /**
     * Loads a single metric report with given identifier.
     * @param id The identifier.
     * @return an observable.
     */
    loadBy(id: number): Observable<MetricReport> {
        return this.http.get<MetricReportJSON>(`${METRIC_REPORTS_ENDPOINT}/${id}`).pipe(
            map(raw => MetricReport.deserialize(raw))
        );
    }

    /**
     * Bulk updates the accounted state for all given ids.
     * @param ids The array of ids.
     * @param accounted The new accounted state.
     * @return an observable.
     */
    bulkUpdateAccountedStatus(ids: number[], accounted: boolean): Observable<MetricReport[]> {
        let updates: MetricReportStatusUpdateJSON[] = [];
        ids.forEach(id => updates.push({id, accounted}));
        return this.http.patch<MetricReportJSON[]>(METRIC_REPORTS_ENDPOINT, updates).pipe(
            map(raws =>
                raws.map(raw => MetricReport.deserialize(raw)))
        );
    }
}
