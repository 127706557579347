import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Pricing} from '../../../core/services/pricing';

@Component({
    selector: 'app-pricing-form',
    templateUrl: './pricing-form.component.html',
    styleUrls: ['./pricing-form.component.scss']
})
export class PricingFormComponent {

    pricingForm: FormGroup;

    constructor(private dialogRef: MatDialogRef<PricingFormComponent>,
                private formBuilder: FormBuilder,
                @Inject(MAT_DIALOG_DATA) private pricing?: Pricing) {
        this.pricingForm = this.formBuilder.group({
            fromDate: ['', [Validators.required]],
            price: ['', [Validators.required]]
        });
        this.resetFormFor(pricing);
    }

    onSave(): void {
        if (this.pricingForm.invalid) {
            return;
        }
        if (this.pricing) {
            Object.assign(this.pricing, this.pricingForm.value);
            return this.dialogRef.close(this.pricing);
        }
        this.dialogRef.close(this.pricingForm.value);
    }

    private resetFormFor(pricing?: Pricing): void {
        this.pricingForm.reset(pricing ? pricing : undefined);
    }
}
