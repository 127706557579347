import {animate, animateChild, AnimationMetadata, group, query, style, transition, trigger} from '@angular/animations';

/**
 * Animation constants.
 */

// <editor-fold desc="Animation metadata">
const rightTransitionAnimation: AnimationMetadata[] = [
    style({position: 'relative'}),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 64px)'
        })
    ]),
    query(':enter', [
        style({left: '100%'})
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({left: '-100%'}))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({left: '0%'}))
        ])
    ]),
    query(':enter', animateChild()),
];

const leftTransitionAnimation: AnimationMetadata[] = [
    style({position: 'relative'}),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 64px)'
        })
    ]),
    query(':enter', [
        style({left: '-100%'})
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({left: '100%'}))
        ]),
        query(':enter', [
            animate('300ms ease-out', style({left: '0%'}))
        ])
    ]),
    query(':enter', animateChild()),
];

const upTransitionAnimation: AnimationMetadata[] = [
    style({position: 'relative'}),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 64px)'
        })
    ]),
    query(':leave', [
        style({'z-index': 2})
    ]),
    query(':leave', animateChild()),
    group([
        query(':leave', [
            animate('300ms ease-out', style({top: '-100%'}))
        ]),
    ]),
    query(':enter', animateChild()),
];

const downTransitionAnimation: AnimationMetadata[] = [
    style({position: 'relative'}),
    query(':enter, :leave', [
        style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 'calc(100vh - 64px)'
        })
    ]),
    query(':enter', [
        style({top: '-100%', 'z-index': 2})
    ]),
    query(':leave', animateChild()),
    group([
        query(':enter', [
            animate('300ms ease-out', style({top: 0}))
        ])
    ]),
    query(':enter', animateChild()),
];
// </editor-fold>

export const routingAnimation =
    trigger('routeAnimations', [
        transition('HomePage => MetricReportsPage', rightTransitionAnimation),
        transition('HomePage => AdministrationPage', rightTransitionAnimation),
        transition('MetricReportsPage => AdministrationPage', rightTransitionAnimation),
        transition('MetricReportsPage => HomePage', leftTransitionAnimation),
        transition('AdministrationPage => MetricReportsPage', leftTransitionAnimation),
        transition('AdministrationPage => HomePage', leftTransitionAnimation),
        transition('LoginPage => *', upTransitionAnimation),
        transition('* => LoginPage', downTransitionAnimation)
    ]);
