import {Component, OnInit, ViewChild} from '@angular/core';
import {MetricReportStatisticsService} from '../../core/services/statistics/metric-report-statistics.service';
import {BaseChartDirective, Color, PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';
import {CustomerBasedMetricReportCount, MonthlyBasedMetricReportCount} from '../../core/services/statistics/metric-report-statistics';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-customer-report-count-chart',
    templateUrl: './customer-report-count-chart.component.html',
    styleUrls: ['./customer-report-count-chart.component.scss']
})
export class CustomerReportCountChartComponent implements OnInit {

    private customerBasedCount: CustomerBasedMetricReportCount[] = [];

    dataReady = false;

    chartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                formatter: (value: any, ctx: any) => ''
            }
        }
    };
    chartType: ChartType = 'doughnut';
    chartLegend = true;
    plugins: PluginServiceGlobalRegistrationAndOptions[] = [];

    dataSets: ChartDataSets[] = [];
    labels: string[] = [];

    constructor(private metricReportStatisticsService: MetricReportStatisticsService) {
    }

    ngOnInit(): void {
        this.metricReportStatisticsService.loadCustomerBasedCount().subscribe(values => {
            this.customerBasedCount = values.sort((a, b) => a > b ? -1 : 1);
            this.setupChartData();
        });
    }

    private setupChartData(): void {
        this.labels.length = 0;
        this.dataSets.length = 0;
        const data: number[] = [];
        this.customerBasedCount.forEach(entry => {
            this.labels.push(entry.customer.name);
            data.push(entry.count);
        });
        this.dataSets.push({data, label: 'Berichte pro Mandant'});
        this.dataReady = true;
    }

}
