export interface MonthlyBasedMetricReportCountJSON {
    month: string;
    count: number;
}

export class MonthlyBasedMetricReportCount {
    constructor(
        public month: Date,
        public count: number
    ) {
    }

    static deserialize(raw: MonthlyBasedMetricReportCountJSON): MonthlyBasedMetricReportCount {
        return new MonthlyBasedMetricReportCount(new Date(raw.month), raw.count);
    }
}

export interface Customer {
    id: number;
    name: string;
}

export interface CustomerBasedMetricReportCount {
    customer: Customer;
    count: number;
}

export interface CategoryBasedMetricReportCount {
    category: string;
    count: number;
}

export interface User {
    id: number;
    userId: string;
    firstName: string;
    secondName: string;
}

export interface UserBasedMetricReportCount {
    user: User;
    count: number;
}
