import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
    CategoryBasedMetricReportCount,
    CustomerBasedMetricReportCount,
    MonthlyBasedMetricReportCount,
    MonthlyBasedMetricReportCountJSON,
    UserBasedMetricReportCount
} from './metric-report-statistics';
import {map} from 'rxjs/operators';

const METRIC_REPORT_STATISTICS_ENDPOINT = '/api/stats/metric-reports';
const MONTH_BASED_REPORT = 'month';
const CUSTOMER_BASED_REPORT = 'customer';
const CATEGORY_BASED_REPORT = 'category';
const REPORT_CREATOR_BASED_REPORT = 'report-creator';

@Injectable({
    providedIn: 'root'
})
export class MetricReportStatisticsService {

    constructor(private http: HttpClient) {
    }

    loadMonthlyBasedCount(): Observable<MonthlyBasedMetricReportCount[]> {
        return this.http.get<MonthlyBasedMetricReportCountJSON[]>(`${METRIC_REPORT_STATISTICS_ENDPOINT}/${MONTH_BASED_REPORT}`).pipe(
            map(raws => raws.map(raw => MonthlyBasedMetricReportCount.deserialize(raw)))
        );
    }

    loadCustomerBasedCount(): Observable<CustomerBasedMetricReportCount[]> {
        return this.http.get<CustomerBasedMetricReportCount[]>(`${METRIC_REPORT_STATISTICS_ENDPOINT}/${CUSTOMER_BASED_REPORT}`);
    }

    loadCategoryBasedCount(): Observable<CategoryBasedMetricReportCount[]> {
        return this.http.get<CategoryBasedMetricReportCount[]>(`${METRIC_REPORT_STATISTICS_ENDPOINT}/${CATEGORY_BASED_REPORT}`);
    }

    loadReportCreatorBasedCount(): Observable<UserBasedMetricReportCount[]> {
        return this.http.get<UserBasedMetricReportCount[]>(`${METRIC_REPORT_STATISTICS_ENDPOINT}/${REPORT_CREATOR_BASED_REPORT}`);
    }
}
