import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {StatisticsRoutingModule} from './statistics-routing.module';
import {MetricReportCountChartComponent} from './metric-report-count-chart/metric-report-count-chart.component';
import {ChartsModule} from 'ng2-charts';
import {FlexModule} from '@angular/flex-layout';
import {CustomerReportCountChartComponent} from './customer-report-count-chart/customer-report-count-chart.component';
import {CategoryReportCountChartComponent} from './category-report-count-chart/category-report-count-chart.component';
import {CreatorReportCountChartComponent} from './creator-report-count-chart/creator-report-count-chart.component';

@NgModule({
    declarations: [
        MetricReportCountChartComponent,
        CustomerReportCountChartComponent,
        CategoryReportCountChartComponent,
        CreatorReportCountChartComponent],
    exports: [
        MetricReportCountChartComponent,
        CustomerReportCountChartComponent,
        CategoryReportCountChartComponent,
        CreatorReportCountChartComponent
    ],
    imports: [
        CommonModule,
        ChartsModule,
        StatisticsRoutingModule,
        FlexModule
    ]
})
export class StatisticsModule {
}
