import {Person, PersonJSON} from './person';

/**
 * JSON representation of a metric report.
 */
export interface MetricReportJSON {
    id: number;
    accountingKey: string;
    customer: string;
    windFarm: string;
    windTurbine: string;
    contractor: string | null;
    category: string;
    performedChecklistRevision: number;
    performedChecklistCreatedOn: string;
    performedChecklistCreator: PersonJSON;
    createdOn: string;
    creator: PersonJSON;
    accounted: boolean;
}

/**
 * Represents a metric report.
 */
export class MetricReport {
    constructor(
        public id: number,
        public accountingKey: string,
        public customer: string,
        public windFarm: string,
        public windTurbine: string,
        public contractor: string | null,
        public category: string,
        public performedChecklistRevision: number,
        public performedChecklistCreatedOn: Date,
        public performedChecklistCreator: Person,
        public createdOn: Date,
        public creator: Person,
        public accounted: boolean
    ) {
    }

    static deserialize(raw: MetricReportJSON): MetricReport {
        return new MetricReport(raw.id, raw.accountingKey, raw.customer, raw.windFarm, raw.windTurbine, raw.contractor,
            raw.category, raw.performedChecklistRevision, new Date(raw.performedChecklistCreatedOn), raw.performedChecklistCreator,
            new Date(raw.createdOn), raw.creator, raw.accounted);
    }
}
