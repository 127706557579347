import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../core/security/authentication.service';
import {Router} from '@angular/router';
import {MessagesService} from '../core/messages/messages.service';
import {AuthenticationRequest} from '../core/security/authentication-request';

/**
 * Login component.
 */
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    loginForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private authenticationService: AuthenticationService,
                private router: Router,
                private messagesService: MessagesService) {
        this.loginForm = this.formBuilder.group({
            userId: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    /**
     * Event handler that performs the login.
     */
    onLogin(): void {
        if (this.loginForm.valid) {
            const authenticationRequest: AuthenticationRequest = this.loginForm.value;
            this.authenticationService.login(authenticationRequest).subscribe(() => {
                if (this.authenticationService.isLoggedIn()) {
                    const redirect = this.authenticationService.redirectUrl ? this.authenticationService.redirectUrl : '/';
                    this.router.navigate([redirect]);
                }
            }, () => {
                this.messagesService.showErrorMessage('Login was not successful!');
            });
        }
    }
}
