import {Component, Inject} from '@angular/core';
import {MetricReport} from '../../../core/services/metric-report';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-metric-report-details',
    templateUrl: './metric-report-details.component.html',
    styleUrls: ['./metric-report-details.component.scss']
})
export class MetricReportDetailsComponent {

    constructor(private dialogRef: MatDialogRef<MetricReportDetailsComponent>,
                @Inject(MAT_DIALOG_DATA) private metricReport?: MetricReport) {
    }

}
