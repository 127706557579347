import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-metric-report-status-form',
    templateUrl: './metric-report-status-form.component.html',
    styleUrls: ['./metric-report-status-form.component.scss']
})
export class MetricReportStatusFormComponent {

    accounted?: boolean;

    constructor(private dialogRef: MatDialogRef<MetricReportStatusFormComponent>) {
    }

    onSubmit(): void {
        this.dialogRef.close(this.accounted);
    }
}
