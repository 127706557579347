import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pricing, PricingJSON, PricingUpdateJSON} from './pricing';
import {map} from 'rxjs/operators';
import * as moment from 'moment';

const PRICING_ENDPOINT = '/api/pricings';
const CUSTOMER_ENDPOINT = '/api/customers';
const DATE_FORMAT = 'YYYY-MM-DD';

@Injectable({
    providedIn: 'root'
})
export class PricingService {

    constructor(private http: HttpClient) {
    }

    /**
     * Loads all pricing informtion for the overview page.
     * @return an observable.
     */
    loadAll(): Observable<Pricing[]> {
        return this.http.get<PricingJSON[]>(PRICING_ENDPOINT).pipe(
            map(raws => raws.map(raw => Pricing.deserialize(raw)))
        );
    }

    loadAllBy(customerId: number): Observable<Pricing[]> {
        return this.http.get<PricingJSON[]>(`${CUSTOMER_ENDPOINT}/${customerId}/pricings`).pipe(
            map(raws => raws.map(raw => Pricing.deserialize(raw)))
        );
    }

    save(pricing: Pricing, customerId: number): Observable<Pricing> {
        const pricingJSON: PricingUpdateJSON = {
            id: pricing.id || null,
            fromDate: moment(pricing.fromDate).format(DATE_FORMAT),
            price: pricing.price
        };
        return this.http.put<PricingJSON>(`${CUSTOMER_ENDPOINT}/${customerId}/pricings`, pricingJSON).pipe(
            map(raw => Pricing.deserialize(raw))
        );
    }

    delete(pricing: Pricing): Observable<any> {
        return this.http.delete(`${PRICING_ENDPOINT}/${pricing.id}`);
    }
}
