import {Role} from './role';

export class UserDetails {
    constructor(
        public id: number,
        public userId: string,
        public firstName: string,
        public secondName: string,
        public authorities: string[],
    ) {
    }

    get fullName() {
        return this.firstName ? `${this.firstName} ${this.secondName}` : this.secondName;
    }

    hasRole(role: Role): boolean {
        return this.authorities.map(value => value.toLowerCase()).indexOf(role.toLowerCase()) !== -1;
    }

    hasAnyRole(roles: Role[]): boolean {
        return roles.some(role => this.hasRole(role));
    }
}
