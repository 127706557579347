import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatButtonModule, MatDialogModule, MatSnackBarModule} from '@angular/material';
import {AuthenticationInterceptor} from './security/authentication-interceptor';
import {HasRoleDirective} from './security/has-role.directive';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [HasRoleDirective, ConfirmDialogComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        MatSnackBarModule,
        MatButtonModule,
        MatDialogModule
    ],
    exports: [
        HasRoleDirective,
        MatDialogModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 3000, panelClass: 'info-message'}}
    ],
    entryComponents: [
        ConfirmDialogComponent
    ]
})
export class CoreModule {
}
