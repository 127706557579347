import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-detail-grid-item',
    template: '<ng-template #content><ng-content></ng-content></ng-template>'
})
export class DetailGridItemComponent {

    @Input() colSpan = 1;
    @Input() label = '';

    @ViewChild('content') content?: TemplateRef<any>;

}
