import {Component, OnInit} from '@angular/core';
import {CategoryBasedMetricReportCount} from '../../core/services/statistics/metric-report-statistics';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {MetricReportStatisticsService} from '../../core/services/statistics/metric-report-statistics.service';
import {PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';

@Component({
    selector: 'app-category-report-count-chart',
    templateUrl: './category-report-count-chart.component.html',
    styleUrls: ['./category-report-count-chart.component.scss']
})
export class CategoryReportCountChartComponent implements OnInit {

    private categoryBasedCount: CategoryBasedMetricReportCount[] = [];

    dataReady = false;

    chartOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                formatter: (value: any, ctx: any) => ''
            }
        }
    };
    chartType: ChartType = 'doughnut';
    chartLegend = true;
    plugins: PluginServiceGlobalRegistrationAndOptions[] = [];

    dataSets: ChartDataSets[] = [];
    labels: string[] = [];

    constructor(private metricReportStatisticsService: MetricReportStatisticsService) {
    }

    ngOnInit(): void {
        this.metricReportStatisticsService.loadCategoryBasedCount().subscribe(values => {
            this.categoryBasedCount = values.sort((a, b) => a > b ? -1 : 1);
            this.setupChartData();
        });
    }

    private setupChartData(): void {
        this.labels.length = 0;
        this.dataSets.length = 0;
        const data: number[] = [];
        this.categoryBasedCount.forEach(entry => {
            this.labels.push(entry.category);
            data.push(entry.count);
        });
        this.dataSets.push({data, label: 'Berichte pro Kategorie'});
        this.dataReady = true;
    }
}
