import {Component, OnInit} from '@angular/core';
import {Pricing} from '../../../core/services/pricing';
import {ActivatedRoute} from '@angular/router';
import {PricingService} from '../../../core/services/pricing.service';
import {MatDialog, MatDialogConfig, MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {PricingFormComponent} from '../pricing-form/pricing-form.component';
import {ConfirmDialogService} from '../../../core/confirm-dialog/confirm-dialog.service';
import {MessagesService} from '../../../core/messages/messages.service';

@Component({
    selector: 'app-pricing-details',
    templateUrl: './pricing-details.component.html',
    styleUrls: ['./pricing-details.component.scss']
})
export class PricingDetailsComponent implements OnInit {

    dataSource = new MatTableDataSource<Pricing>();
    selectionModel: SelectionModel<Pricing>;

    columnsToDisplay = ['selection', 'fromDate', 'price'];

    private customerId?: number;

    constructor(private route: ActivatedRoute,
                private pricingService: PricingService,
                private confirmDialogService: ConfirmDialogService,
                private messagesService: MessagesService,
                private dialog: MatDialog) {
        this.selectionModel = new SelectionModel<Pricing>(false, []);
    }

    ngOnInit() {
        this.loadPricingDetails();
    }

    onAdd(): void {
        this.openPricingFormFor().subscribe(newPricing => {
            this.updatePricing(newPricing);
            this.messagesService.showInfoMessage('Der Preis wurde angelegt.');
        });
    }

    onEdit(): void {
        this.openPricingFormFor(this.selectedPricing).subscribe(updatedPricing => {
            this.updatePricing(updatedPricing);
            this.messagesService.showInfoMessage('Der Preis wurde aktualisiert.');
        });
    }

    onDelete(): void {
        this.confirmDialogService.confirm('Preis löschen?', 'Soll der ausgewählte Preis wirklich gelöscht werden?',
            'Löschen').subscribe(result => {
            if (result) {
                this.deleteSelectedPricing();
                this.messagesService.showInfoMessage('Der Preis wurde gelöscht.');
            }
        });
    }

    get selectedPricing() {
        return this.selectionModel.selected[0];
    }

    private loadPricingDetails(): void {
        const customerIdString = this.route.snapshot.paramMap.get('customerId');
        if (customerIdString != null) {
            this.customerId = Number(customerIdString);
            this.reloadDetailsForCurrentCustomer();
        }
    }

    private reloadDetailsForCurrentCustomer(): void {
        if (this.customerId) {
            this.pricingService.loadAllBy(this.customerId).subscribe(values => {
                this.dataSource.data = values.sort((a, b) => a.fromDate.getTime() - b.fromDate.getTime());
            });
        }
    }

    private updatePricing(pricing: Pricing): void {
        if (pricing && this.customerId) {
            this.pricingService.save(pricing, this.customerId).subscribe(() => this.reloadDetailsForCurrentCustomer());
        }
    }

    private deleteSelectedPricing(): void {
        if (this.selectedPricing) {
            this.pricingService.delete(this.selectedPricing).subscribe(() => this.reloadDetailsForCurrentCustomer());
        }
    }

    private openPricingFormFor(pricing?: Pricing): Observable<Pricing> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '300px';
        dialogConfig.disableClose = true;
        if (pricing) {
            dialogConfig.data = pricing;
        }
        const dialogRef = this.dialog.open(PricingFormComponent, dialogConfig);
        return dialogRef.afterClosed();
    }
}
