import {Component, OnInit} from '@angular/core';
import {PricingService} from '../../core/services/pricing.service';
import {Pricing} from '../../core/services/pricing';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

    pricing: Pricing[] = [];

    constructor(private pricingService: PricingService, private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.reloadPricing();
    }

    onDetails(selectedPricing: Pricing): void {
        this.router.navigate(['details', {customerId: selectedPricing.customer.id}], {relativeTo: this.route});
    }

    private reloadPricing(): void {
        this.pricingService.loadAll().subscribe(values => this.pricing = values);
    }
}
