import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserDetails} from './user-details';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationResponse} from './authentication-response';
import {AuthenticationRequest} from './authentication-request';
import * as moment from 'moment';
import {Moment} from 'moment';

const AUTHENTICATION_ENDPOINT = '/api/auth';
const LOGIN = '/login';
const LOCAL_STORAGE_TOKEN = 'id_token';
const LOCAL_STORAGE_EXPIRES_AT = 'expires_at';
const LOCAL_STORAGE_USER = 'user_details';

/**
 * Authentication service.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<UserDetails | null>;
    currentUser: Observable<UserDetails | null>;

    redirectUrl?: string;

    static getToken(): string | null {
        return localStorage.getItem(LOCAL_STORAGE_TOKEN);
    }

    constructor(private http: HttpClient) {
        const userJSON = localStorage.getItem(LOCAL_STORAGE_USER);
        let userDetails: UserDetails | null = null;
        if (userJSON) {
            const userDetailsJSON = JSON.parse(userJSON);
            userDetails = new UserDetails(userDetailsJSON.id, userDetailsJSON.userId, userDetailsJSON.firstName, userDetailsJSON.secondName,
                userDetailsJSON.authorities);
        }
        this.currentUserSubject = new BehaviorSubject<UserDetails | null>(userDetails);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    login(authenticationRequest: AuthenticationRequest): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(`${AUTHENTICATION_ENDPOINT}${LOGIN}`, authenticationRequest)
            .pipe(
                tap(authenticationResponse => this.setSession(authenticationResponse))
            );
    }

    logout(): void {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_EXPIRES_AT);
        localStorage.removeItem(LOCAL_STORAGE_USER);
        this.currentUserSubject.next(null);
    }

    isLoggedIn(): boolean {
        return moment().isBefore(this.getExpiration());
    }

    get currentUserDetails(): UserDetails | null {
        return this.currentUserSubject.value;
    }

    private setSession(authenticationResponse: AuthenticationResponse): void {
        localStorage.setItem(LOCAL_STORAGE_TOKEN, authenticationResponse.token);
        localStorage.setItem(LOCAL_STORAGE_EXPIRES_AT, authenticationResponse.expiration.toString());
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(authenticationResponse.currentUser));
        const userDetails = new UserDetails(authenticationResponse.currentUser.id,
            authenticationResponse.currentUser.userId, authenticationResponse.currentUser.firstName,
            authenticationResponse.currentUser.secondName, authenticationResponse.currentUser.authorities);
        this.currentUserSubject.next(userDetails);
    }

    // noinspection JSMethodCanBeStatic
    private getExpiration(): Moment {
        return moment(Number(localStorage.getItem(LOCAL_STORAGE_EXPIRES_AT)));
    }

}
