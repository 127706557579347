/**
 * JSON representation of a customer.
 */
export interface CustomerJSON {
    id: number;
    name: string;
}

/**
 * Represents a customer.
 */
export class Customer {
    constructor(
        public id: number,
        public name: string
    ) {
    }

    static deserialize(raw: CustomerJSON): Customer {
        return new Customer(raw.id, raw.name);
    }
}
