import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Pricing} from '../../../core/services/pricing';

@Component({
    selector: 'app-pricing-card',
    templateUrl: './pricing-card.component.html',
    styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent {

    @Input() pricing!: Pricing;
    @Output() details = new EventEmitter<Pricing>();

    onDetails(): void {
        this.details.emit(this.pricing);
    }
}
