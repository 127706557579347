import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../core/security/auth.guard';
import {Role} from '../core/security/role';
import {PricingComponent} from './pricing/pricing.component';
import {PricingDetailsComponent} from './pricing/pricing-details/pricing-details.component';

const routes: Routes = [
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: [Role.SYSTEM_ADMIN]},
        children: [
            {path: '', redirectTo: 'pricing', pathMatch: 'full', data: {roles: [Role.SYSTEM_ADMIN]}},
            {
                path: 'pricing', children: [
                    {path: '', component: PricingComponent, data: {animation: 'AdministrationPage', roles: [Role.SYSTEM_ADMIN]}},
                    {path: 'details', component: PricingDetailsComponent, data: {roles: [Role.SYSTEM_ADMIN]}}
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {
}
