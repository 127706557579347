import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ChartData} from '../../core/services/chart-data';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
    selector: 'app-metric-reports-chart-dialog',
    templateUrl: './metric-reports-chart-dialog.component.html',
    styleUrls: ['./metric-reports-chart-dialog.component.scss']
})
export class MetricReportsChartDialogComponent implements OnInit {
    dataReady = false;
    chartOptions: ChartOptions = {
        showLines: true,
        responsive: true,
        maintainAspectRatio: true,
        tooltips: {enabled: false},
        plugins: {
            datalabels: {
                formatter: (value: any, ctx: any) => ctx.chart.data.labels[ctx.dataIndex]
            }
        }
    };
    chartType: ChartType = 'pie';
    chartLegend = false;
    colors: Color[] = [{backgroundColor: 'rgba(255, 171, 0, 0.75)'}];
    dataSets: ChartDataSets[] = [];
    labels: string[] = [];
    plugins: PluginServiceGlobalRegistrationAndOptions[] = [pluginDataLabels];

    constructor(private dialogRef: MatDialogRef<MetricReportsChartDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private chartData: ChartData) {
    }

    ngOnInit() {
        this.setupChartData();
    }

    private setupChartData(): void {
        this.labels.length = 0;
        this.dataSets.length = 0;

        this.labels.push(this.buildLabelFor(this.chartData));
        const data: number[] = [];
        data.push(this.chartData.sum);
        this.dataSets.push({data, label: 'Kostenaufstellung'});
        this.dataReady = true;
    }

    private buildLabelFor(chartData: ChartData) {
        return `WP4MRO: ${chartData.count} Stck, ${chartData.sum} €`;
    }

}
