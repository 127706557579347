import {Component, OnInit} from '@angular/core';
import {MetricReportStatisticsService} from '../../core/services/statistics/metric-report-statistics.service';
import {MonthlyBasedMetricReportCount} from '../../core/services/statistics/metric-report-statistics';
import {Color, PluginServiceGlobalRegistrationAndOptions} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';

@Component({
    selector: 'app-metric-report-count-chart',
    templateUrl: './metric-report-count-chart.component.html',
    styleUrls: ['./metric-report-count-chart.component.scss']
})
export class MetricReportCountChartComponent implements OnInit {

    private monthlyBasedCount: MonthlyBasedMetricReportCount[] = [];

    dataReady = false;

    chartOptions: ChartOptions = {
        showLines: true,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                formatter: (value: any, ctx: any) => ''
            }
        }
    };
    chartType: ChartType = 'bar';
    chartLegend = false;
    colors: Color[] = [{backgroundColor: 'rgba(255, 171, 0, 0.75)'}];
    plugins: PluginServiceGlobalRegistrationAndOptions[] = [];

    dataSets: ChartDataSets[] = [];
    labels: string[] = [];

    constructor(private metricReportStatisticsService: MetricReportStatisticsService) {
    }

    ngOnInit(): void {
        this.metricReportStatisticsService.loadMonthlyBasedCount().subscribe(values => {
            this.monthlyBasedCount = values.sort((a, b) => a.month.getTime() - b.month.getTime());
            this.setupChartData();
        });
    }

    private setupChartData(): void {
        this.labels.length = 0;
        this.dataSets.length = 0;
        const data: number[] = [];
        this.monthlyBasedCount.forEach(entry => {
            this.labels.push(entry.month.toLocaleDateString('de', {day: undefined, month: 'short', year: 'numeric'}));
            data.push(entry.count);
        });
        this.dataSets.push({data, label: 'Abrechnungsrelevante Berichte'});
        this.dataReady = true;
    }
}
