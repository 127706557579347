import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {MessagesService} from '../messages/messages.service';
import {Role} from './role';
import {UserDetails} from './user-details';

/**
 * Auth guard for enabling security checks on routing.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private messagesService: MessagesService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkLoginFor(state.url, route.data.roles);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(childRoute, state);
    }

    private checkLoginFor(url: string, roles?: Role[]): boolean {
        const currentUser = this.authenticationService.currentUserDetails;
        if (this.authenticationService.isLoggedIn() && currentUser) {
            if (roles) {
                return this.checkAuthorization(currentUser, roles);
            } else {
                return this.denyAccessWithWarning();
            }
        }
        this.authenticationService.redirectUrl = url;

        if (url !== '/') {
            this.messagesService.showErrorMessage('No valid login.');
        }
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['/login']);
        return false;
    }

    private checkAuthorization(user: UserDetails, roles: Role[]): boolean {
        if (roles.some(role => user.hasRole(role))) {
            return true;
        }

        return this.denyAccessWithWarning();
    }

    private denyAccessWithWarning(): boolean {
        this.messagesService.showErrorMessage('Ungenügende Berechtigung.');
        this.authenticationService.logout();
        return false;
    }

}
