import {Component, OnInit} from '@angular/core';
import {NavigationItem} from '../shared/navigation-item';
import {AuthenticationService} from '../core/security/authentication.service';
import {UserDetails} from '../core/security/user-details';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {Role} from '../core/security/role';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    navigationItems: NavigationItem[] = [
        {link: '/', name: 'Start', exactMatch: true, requiredRoles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]},
        {link: '/accounting', name: 'Abrechnung', exactMatch: false, requiredRoles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]},
        {link: '/admin', name: 'Administration', exactMatch: false, requiredRoles: [Role.SYSTEM_ADMIN]}
    ];

    currentUser$!: Observable<UserDetails | null>;

    constructor(private authenticationService: AuthenticationService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.currentUser$ = this.authenticationService.currentUser;
    }

    /**
     * Event handler that performs the logout.
     */
    onLogout(): void {
        this.authenticationService.logout();
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigateByUrl('/login');
    }

    isLoggedIn(): boolean {
        return this.authenticationService.isLoggedIn();
    }

    isAuthorized(navigationItem: NavigationItem): boolean {
        const currentUser = this.authenticationService.currentUserDetails;
        if (currentUser) {
            return currentUser.hasAnyRole(navigationItem.requiredRoles);
        }
        return false;
    }
}
