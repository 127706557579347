import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    title = '';
    message = '';
    confirmActionTitle = 'Okay';

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    }

    close(result: boolean): void {
        this.dialogRef.close(result);
    }

}
