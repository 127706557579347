import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../core/security/auth.guard';
import {MetricReportsComponent} from './metric-reports/metric-reports.component';
import {Role} from '../core/security/role';

const routes: Routes = [
    {
        path: 'accounting',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]},
        children: [
            {path: '', redirectTo: 'metric-reports', pathMatch: 'full', data: {roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]}},
            {
                path: 'metric-reports',
                component: MetricReportsComponent,
                data: {animation: 'MetricReportsPage', roles: [Role.SYSTEM_ADMIN, Role.CUSTOMER_ADMIN]}
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountingRoutingModule {
}
